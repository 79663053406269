'use strict';
$(function () {
  var iframe = $('iframe').get(0);

  var popup = $('.main-popup');
  popup.click(function(e){
    $('.rightMainContent').removeClass('pulsate');
    var squeeze = $('.to-squeeze');
    squeeze.removeClass('squeeze');
    iframe.contentWindow.flowplayer().load();
    e.preventDefault();
  });

  var close = $('.popup-backdrop, .mfp-close');
  close.click(function(e){
    $('.rightMainContent').addClass('pulsate');
    var squeeze = $('.to-squeeze');
    squeeze.addClass('squeeze');
    iframe.contentWindow.flowplayer().unload();
  });

});
